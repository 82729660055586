import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { first } from 'rxjs/operators';
import { CURRENT_LANG } from '../tokens/lang.tokens';

export const LANG_KEY = 'language';

@Injectable({
    providedIn: 'root',
})
export class TranslationManagerService {

    constructor(@Inject(CURRENT_LANG) private readonly currentLanguage: string,
                private readonly translateService: TranslateService,
                private readonly primeNgConfig: PrimeNGConfig) {
        this.initialize();
    }

    private initialize(): void {
        this.translateService.get(`primeng`).pipe(
            first()
        ).subscribe((res) => this.primeNgConfig.setTranslation(res));
    }

    switchLang(): void {
        localStorage.setItem(LANG_KEY, this.currentLanguage === 'en' ? 'pl' : 'en');

        location.reload();
    }

    static getLanguage(): string {
        return localStorage.getItem(LANG_KEY) || 'pl';
    }

}
