import { Component } from '@angular/core';
import { ThemeService } from './core/services/theme/theme.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

@Component({
    selector: `app-root`,
    template: `<router-outlet></router-outlet>`,
})
export class AppComponent {
    constructor(
        private readonly themeService: ThemeService,
        private readonly translateService: TranslateService,
        private readonly titleService: Title,
    ) {
    }

    ngOnInit() {
        this.themeService.set();
        this.setTitle(environment.instanceConfiguration.global.appTitle);

        document.head.innerHTML += environment.instanceConfiguration.headHtml;
    }

    setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }
}
