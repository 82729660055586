import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

const { theme } = environment.instanceConfiguration;

@Injectable()
export class ThemeService {
    variables = document.getElementById(`variables`) as HTMLStyleElement;

    set() {
        this.variables.innerHTML = theme;
    }
}
