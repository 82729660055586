const modelName = `[App]`;

export class Get {
    static readonly type = `${modelName} Get`;
}

export class GetPolicies {
    static readonly type = `${modelName} GetPolicies`;
}

export class GetFaqs {
    static readonly type = `${modelName} GetFaqs`;
}

export class GetUser {
    static readonly type = `${modelName} GetUser`;
}

export class Select {
    static readonly type = `${modelName} Select`;
    constructor(public slug: string) {}
}

export class Deduce {
    static readonly type = `${modelName} Deduce`;
}

export class ShowHeaderDropdown {
    static readonly type = `${modelName} ShowHeaderDropdown`;
    constructor(public show: boolean) {}
}

export class ShowChooseDialog {
    static readonly type = `${modelName} ShowChooseDialog`;
    constructor(public show: boolean) {}
}

export class GetTickets {
    static readonly type = `${modelName} GetTickets`;
}

export class GetProducts {
    static readonly type = `${modelName} GetProducts`;
}

export class GetSuppliers {
    static readonly type = `${modelName} GetSuppliers`;
}

export class GetStations {
    static readonly type = `${modelName} GetStations`;
}

export class GetPickupPoints {
    static readonly type = `${modelName} GetPickupPoints`;
}

export class HideCart {
    static readonly type = `${modelName} hideCart`;
    constructor(public hide: boolean) {}
}
